import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Logo from "../images/logo_dark.svg"
import React from "react"

function Footer() {
  return (
    <div className="flex flex-col items-center p-4 text-white bg-charleston-green">
      <img src={Logo} alt="meticulous-gardens" className="h-12 my-1" />
      <span className="text-xs">&copy; {new Date().getFullYear()}</span>
      <div className="my-4">
        <a
          href="https://facebook.com/meticulousgardens/"
          className="p-2 -m-2"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>
      </div>
      <div className="mt-4 text-xs text-center">
        <p>Website by Christian Tute</p>
        <p className="my-2">
          Icons by ProSymbols from the{" "}
          <a href="https://thenounproject.com/">Noun Project</a>
        </p>
      </div>
    </div>
  )
}

export default Footer
