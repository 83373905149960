import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { faBars } from "@fortawesome/free-solid-svg-icons"

function Header() {
  return null
  return (
    <header className="absolute z-30 w-full text-white">
      <div className="container flex items-center justify-end px-4 py-2 mx-auto">
        <button type="button" className="p-2 -mr-2">
          <FontAwesomeIcon icon={faBars} size="2x" />
        </button>
      </div>
    </header>
  )
}

export default Header
